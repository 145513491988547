import React from 'react';
import { StaticQuery, graphql } from "gatsby"

// Libraries
import _ from 'lodash'

// Third Party
import { Helmet } from "react-helmet"

import favicon from '../img/favicon.png'

const query = graphql`
  {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        defaultKeywords: keywords
      }
    }
  }
`;

function SEO({ title, description, image, pathname, article, keywords, yoast }) {
  return (
    <StaticQuery
      query={query}
      render={({
        site: {
          siteMetadata: {
            defaultKeywords,
            defaultTitle,
            defaultDescription,
            siteUrl,
          },
        },
      }) => {
        if(yoast) {
          const yoastTitleObj = _.find(yoast.meta, ({ property }) => property === 'og:title')
          const yoastDescriptionObj = _.find(yoast.meta, ({ name }) => name === 'description')

          const yoastTitle = _.size(yoastTitleObj) > 0 && yoastTitleObj.content ? yoastTitleObj.content : defaultTitle
          const yoastDescription = _.size(yoastDescriptionObj) > 0 && yoastDescriptionObj.content ? yoastDescriptionObj.content : defaultDescription

          title = title || yoastTitle
          description = description || yoastDescription
        }

        const seo = {
          title: title || defaultTitle,
          description: description || defaultDescription,
          url: `${siteUrl}${pathname || "/"}`,
          keywords: keywords || defaultKeywords
        }

        return (
          <>
            <Helmet title={seo.title}>
              <html lang="nl" />
              <link rel="icon" href={favicon} />
              <meta name="description" content={seo.description} />
              <meta name="keywords" content={seo.keywords} />
              <meta name="image" content={seo.image} />
              {seo.url && <meta property="og:url" content={seo.url} />}
              {(article ? true : null) && (
                <meta property="og:type" content="article" />
              )}
              {seo.title && <meta property="og:title" content={seo.title} />}
              {seo.description && (
                <meta property="og:description" content={seo.description} />
              )}
              {seo.image && <meta property="og:image" content={seo.image} />}
              <meta name="twitter:card" content="summary_large_image" />
              {seo.title && <meta name="twitter:title" content={seo.title} />}
              {seo.description && (
                <meta name="twitter:description" content={seo.description} />
              )}
              {seo.image && <meta name="twitter:image" content={seo.image} />}
            </Helmet>
          </>
        )
      }}
    />
  )
}

export default SEO;
